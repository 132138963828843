// @ts-nocheck
/* eslint-disable */

'use client';

import React from 'react';
import {usePathname} from 'next/navigation';
import FlowPage from '@/flow/FlowPage';

const ProfileFlowPage: React.FC = () => {
  const path = usePathname();
  const flowName = path.split('/').pop();

  return <FlowPage flowName={flowName} />;
};
export default ProfileFlowPage;
