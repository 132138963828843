// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {useSearchParams} from 'next/navigation';
import {logger} from '@modules/Core/util/Logger';
import {camelCase, pascalCase} from '@modules/Core/util/strings';
import {Flow} from '@modules/FlowEngine/components/Flow';
import {useFlowJson} from '@modules/FlowEngine/hooks/flowJson';
import {extractFlowInput} from '@modules/FlowEngine/util/helpers/util';

interface _Props {
  flowName?: string;
  stepName?: string;
}

const FlowPage: React.FC<_Props> = ({flowName, stepName}) => {
  const query = useSearchParams();
  // [flowName] is the next.js dynamic route, should be latest part of string
  const flowInput = query.entries();
  const flowJson = useFlowJson();

  logger.debug('FlowPage', {
    flowName,
    stepName,
    flowInput,
    flowJson,
  });

  if (!flowName) {
    // TODO: add error handling
    return 'No Flow Name Found';
  }

  // Check if flow exists in flowJsonState
  // if (!flowJson[flowName]) {
  //   return <NotAuthorizedPage />;
  // }

  return (
    <Flow flowName={pascalCase(flowName)} stepName={stepName && camelCase(stepName)} input={extractFlowInput(query)} />
  );
};
export default FlowPage;
