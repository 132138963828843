// @ts-nocheck
/* eslint-disable */

import {useSelectState} from '@modules/State/hooks/selectState';
import {_AppState} from '@modules/State/types/appState.model';
import {_FlowSchema} from '../types/core.model';

/**
 * This hook returns the flowSchema object from redux by name.
 */
export function useFlowJson(): (name: string) => _FlowSchema {
  const flows = useSelectState((state: _AppState) => state.flowJson?.flows);

  function loadFlow(name: string): _FlowSchema {
    if (!flows) {
      throw new Error('[useFlowJson] Flows not found in the redux state!');
    }

    if (!flows[name]) {
      throw new Error(`[useFlowJson] Flow ${name} not found in the redux state!`);
    }

    return flows[name];
  }

  return loadFlow;
}
